import React, {useState} from 'react';
import Modal from './Modal';

export const useModal = ({ open = false } = {}) => {
    const [isOpen, setIsOpen] = useState(open);

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const onToggle = () => {
        setIsOpen(!isOpen);
    };

    return { onOpen, onClose, isOpen, onToggle };
};
