import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import BootstrapButton from 'react-bootstrap/Button';

const SIZE = {
  lg: 'lg',
  md:'md',
  sm: 'sm',
};

const VARIANT = {
  outline: 'outline',
  fill: 'fill',
  text: 'text'
};

const COLOR = ['primary','secondary','success','warning','danger','info','dark','light'];

export default function Button(props){
  const { size, variant, children, className, color, round , fullWidth ,...others } = props;

  if (variant===VARIANT.outline) {
      return (
          <BootstrapButton
              className={clsx(
                  'dButton',
                  round && 'round',
                  fullWidth && 'fullWidth',
                  size,
                  className
              )}
              variant={`outline-${color}`}
              {...others}
          >
            {children}
          </BootstrapButton>
      );
  }
  else if (variant===VARIANT.fill) {
      return (
          <BootstrapButton
              className={clsx(
                  'dButton',
                  round && 'round',
                  fullWidth && 'fullWidth',
                  size,
                  className
              )}
              variant={color}
              {...others}
          >
              {children}
          </BootstrapButton>
      );
  }
  return (
    <BootstrapButton
      className={clsx(
          'dButton',
          round && 'round' ,
          fullWidth && 'fullWidth',
          'textBtn',
          size,
          className
      )}
      variant={`outline-${color}`}
      {...others}
    >
      {children}
    </BootstrapButton>
  );
}

Button.propTypes = {
  size: PropTypes.oneOf(Object.keys(SIZE)),
  variant: PropTypes.oneOf(Object.keys(VARIANT)),
  color: PropTypes.oneOf(COLOR),
  round: PropTypes.bool,
};

Button.defaultProps = {
    variant: VARIANT.fill,
    size: 'md',
    color:'primary',
};
