import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Input from "./Input";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_MIN,
  VALIDATOR_MAX,
  VALIDATOR_EMAIL
} from './validators';

const VALIDATORS = {
  required: VALIDATOR_REQUIRE,
  email: VALIDATOR_EMAIL,
};
const ERRTXT = {
  required: 'This field is required',
}
export default function InputValidator(props) {
  const {validators, ...others} = props;

  return (
    <Input
      validators={validators && validators.length && validators.map((item)=>(VALIDATORS[item])) }
      errorText={validators && validators.length && validators.map((item)=>(ERRTXT[item]))}
      {...others}
    />
  );
}

InputValidator.propTypes = {
  validators: PropTypes.array,
  errorText: PropTypes.any,
};

InputValidator.defaultProps = {};
