import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import themeReducer from './store/reducers/Theme';
import accountReducer from './store/reducers/Account';

import App from './pages/_app/App';
import ErrorBoundary from './components/theme/ErrorBoundary';
import config from './config'
import './utils/i18n';

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: config.sentry.sampleRate,
});

const rootReducer = combineReducers({
  themeReducer,
  accountReducer,
});
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
