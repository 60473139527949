module.exports = {
  appName: process.env.REACT_APP_NAME || 'Mahaleh Restaurants',
  logo: process.env.REACT_APP_LOGO || '/images/logo-blue.png',
  logoWhite: process.env.REACT_APP_LOGO_WHITE || process.env.REACT_APP_LOGO || '/images/logo.png',
  loginImage: process.env.REACT_APP_LOGIN_IMAGE || '/images/login.png',
  backEndAPIRoute: process.env.BACKEND_API_ROUTE || '',
  colors: {
    branding: process.env.REACT_APP_COLORS_BRANDING || 'blue',
    menu: process.env.REACT_APP_COLORS_MENU || 'green',
    background: process.env.REACT_APP_COLORS_BACKGROUND || 'yellow',
  },
  copyright: {
    default: process.env.REACT_APP_COPYRIGHT || 'Copyright © 2022 Mahaleh. All rights reserved.',
  },
  registrationUrL: process.env.REACT_APP_REGISTRATION_URL || 'signup', //signup, registration
  helpUrl: {
    accounts: {
      default: process.env.REACT_APP_HELP_ACCOUNTS || 'mailto:test.default@test.com',
    },
    businessProfile: {
      businessName: {
        default:
          process.env.REACT_APP_HELP_BUSINESS_PROFILE_BUSINESS || 'mailto:test.default@test1.com',
      },
      branchName: {
        default:
          process.env.REACT_APP_HELP_BUSINESS_PROFILE_BRANCH || 'mailto:test.default@test2.com',
      },
    },
    products: {
      default: process.env.REACT_APP_HELP_PRODUCTS || 'http://help.mahaleh.ir/en/products',
    },
  },
  defaultLanguage: process.env.DEFAULT_LANGUAGES || 'en-US',
  availableLanguages: process.env.AVAILABLE_LANGUAGES || 'en', // separate numbers by comma (,)
  privacy: process.env.PRIVACY || '/privacy',
  terms: process.env.TERMS || '/terms',
  importSampleFile: process.env.REACT_APP_SAMPLE_FILE || '/file/sample.csv',
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || null,
    sampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0,
  },
  country: 'CA',
};
