import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab as BootstrapTabs} from 'react-bootstrap';

const COLOR = ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'dark', 'light'];

export default function Tab(props) {
  const {id, active, options, className, color, onChangeTab} = props;
  const [key, setKey] = useState(active);

  useEffect(() => {
    setKey(active);
    }, [active]);

  function onChange(k) {
    setKey(k)
    if (onChangeTab) {
      onChangeTab(k);
    }
  }

  return (
    <div className={className}>
      <Tabs
        id={id}
        activeKey={key}
        onSelect={onChange}
        className={color}
      >
        {
          options.map((item, index) => (
            <BootstrapTabs
              key={index}
              eventKey={index}
              title={item.title}
              disabled={item.disable}
            >
              {item.body}
            </BootstrapTabs>
          ))
        }
      </Tabs>
    </div>
  );
}
Tab.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.any,
    body: PropTypes.any,
    disable: PropTypes.bool,
  })).isRequired,
  active: PropTypes.number,
  id: PropTypes.string,
  color: PropTypes.oneOf(COLOR),
};
Tab.defaultProps = {
  active: 0,
  color: 'primary'
};
