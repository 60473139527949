import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import clsx from "clsx";

const SIZE = {
  sm: {width: 35, height: 15, handleDiameter: 20},
  md: {width: 44, height: 20, handleDiameter: 26},
  lg: {width: 52, height: 23, handleDiameter: 32}
}

const COLOR = ['primary','secondary','success','warning','danger','info','dark','light'];

export default function Switch(props) {
  const {getValue, size, className, value: valueProps, id, color} = props;
  const [value, setValue] = useState(valueProps);

  useEffect(() => {
    setValue(valueProps);
  }, [valueProps]);

  function handleSwitch(state) {
    setValue(state);
    if (getValue) {
      getValue(state);
    }
  }

  return (
    <ReactSwitch
      id={id}
      className={clsx('dSwitch', color, value && 'active',className)}
      onChange={handleSwitch}
      checked={value}
      onColor="#86d3ff"
      onHandleColor="#2693e6"
      handleDiameter={SIZE[size].handleDiameter}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={SIZE[size].height}
      width={SIZE[size].width}
    />);
}
Switch.propTypes = {
  size: PropTypes.oneOf(Object.keys(SIZE)),
  value: PropTypes.bool,
  color: PropTypes.oneOf(COLOR)
};
Switch.defaultProps = {
  size: 'md',
  value: false,
  color: 'primary'
};
