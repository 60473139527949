export const VALIDATOR_TYPE_REQUIRE = 'REQUIRE';
export const VALIDATOR_TYPE_MINLENGTH = 'MINLENGTH';
export const VALIDATOR_TYPE_MAXLENGTH = 'MAXLENGTH';
export const VALIDATOR_TYPE_MIN = 'MIN';
export const VALIDATOR_TYPE_MAX = 'MAX';
export const VALIDATOR_TYPE_EMAIL = 'EMAIL';

export const VALIDATOR_REQUIRE = () => ({type: VALIDATOR_TYPE_REQUIRE});
export const VALIDATOR_MINLENGTH = val => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val
});
export const VALIDATOR_MAXLENGTH = val => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val
});
export const VALIDATOR_MIN = val => ({type: VALIDATOR_TYPE_MIN, val: val});
export const VALIDATOR_MAX = val => ({type: VALIDATOR_TYPE_MAX, val: val});
export const VALIDATOR_EMAIL = () => ({type: VALIDATOR_TYPE_EMAIL});

export const validate = (value, validators) => {
  let isValid = true;
  let validatorIndex = {};
  for (const i in validators) {
    const validator = validators[i];
    if (validator) {
      if (validator().type === VALIDATOR_TYPE_REQUIRE) {
        isValid = isValid && value && value.toString().trim().length > 0;
      }
      if (validator().type === VALIDATOR_TYPE_MINLENGTH) {
        isValid = isValid && value && value.toString().trim().length >= validator().val;
      }
      if (validator().type === VALIDATOR_TYPE_MAXLENGTH) {
        isValid = isValid && value && value.toString().trim().length <= validator().val;
      }
      if (validator().type === VALIDATOR_TYPE_MIN) {
        isValid = isValid && +value >= validator().val;
      }
      if (validator().type === VALIDATOR_TYPE_MAX) {
        isValid = isValid && +value <= validator().val;
      }
      if (validator().type === VALIDATOR_TYPE_EMAIL) {
        isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
      }
    }
    validatorIndex[i] = isValid;
  }
  return {isValid, validatorIndex};
};
