import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { timeFromInt } from 'time-number';
import ReactTimePicker from 'react-bootstrap-time-picker';

export default function TimePicker(props) {
  const {start, end, step, getValue, value, wrapperClassName, format} = props;
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
    }, [value]);

  function handleChange(time) {
    setState({time});
    if (getValue){
      getValue(timeFromInt(time), time);
    }
  }

  return (
    <div className={wrapperClassName}>
      <ReactTimePicker
        end={end}
        step={step}
        value={state}
        start={start}
        format={format || 12}
        onChange={handleChange}
      />
    </div>
  );
}
TimePicker.propTypes = {
  step: PropTypes.number,
  start: PropTypes.string,
  end: PropTypes.string,
};
TimePicker.defaultProps = {
  step: 60,
  start: '00:00',
  end: '23:59',
};
