import * as action from '../actions/action';

const initialState = {
  openAside: false,
};

const reducer = (state = initialState, payload) => {
  switch (payload.type) {
    case action.OPEN_ASIDE:
      return {
        openAside: true,
      };
    case action.CLOSE_ASIDE:
      return {
        openAside: false,
      };
    case action.TOGGLE_ASIDE:
      return {
        openAside: !state.openAside,
      };
    default:
      return state;
  }
};

export default reducer;
