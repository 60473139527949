import React from 'react';
import PropTypes from 'prop-types';
import { Spinner as BootstrapSpinner } from "react-bootstrap";

const SIZE = ['lg', 'sm'];
const COLOR = ['primary','secondary','success','warning','danger','info','dark','light'];

export default function Spinner(props) {
    const { size, grow, color, className } = props;
    return (
        <BootstrapSpinner
            className={className}
            animation={grow ? 'grow' : 'border' }
            size={size==='sm' && 'sm'}
            variant={color}
        />
    );
}
Spinner.propTypes = {
    size: PropTypes.oneOf(SIZE),
    grow: PropTypes.bool,
    color: PropTypes.oneOf(COLOR),
};
Spinner.defaultProps = {
    size: 'lg',
    color: 'primary',
};
