import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import clsx from 'clsx';

const DIRECTION = ['horizontal', 'vertical'];
const SIZE = {
  lg: 'lg',
  sm: 'sm',
};
const COLOR = ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'dark', 'light'];

const initValue = (value) => {
  if (!value) {
    return [];
  }
  if (!Array.isArray(value) && typeof value !== 'string') {
    return [value];
  }
  return value;
}
export default function Checkbox(props) {
  const {
    id,
    dir,
    size,
    color,
    value,
    options,
    getValue,
    className,
    wrapperClassName,
    ...others
  } = props;
  const [state, setState] = useState(initValue(value));

  useEffect(() => {
    setState(value);
  }, [value]);


  const onChangeHandler = ({event, index}) => {
    const val = event.target.checked;
    setState((prevValue) => {
      const temp = prevValue ? [...prevValue] : [];
      if (val) {
        temp.push(options[index].value)
      } else {
        const saveIndex = temp.indexOf(options[index].value);
        if (saveIndex > -1) {
          temp[saveIndex] = null;
        }
      }

      if (temp && temp.length && getValue) {
        getValue(temp, event);
      }
      return temp;
    });
  }

  return (
    <div className={clsx("checkboxForm", wrapperClassName)}>
      <div className={clsx("dCheckboxWrapper", size, color)}>
        {options.map((option, index) => (
          <Form.Check
            key={index}
            type="checkbox"
            label={option.text}
            className={clsx(option.disable && 'disable',className)}
            id={`${id}-${index}`}
            disabled={option.disable}
            inline={dir === 'horizontal'}
            checked={state && state.length && state.includes(option.value)}
            onChange={(event) => onChangeHandler({event, index})}
            {...others}
          />
        ))}

      </div>
    </div>
  );
}

Checkbox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      value: PropTypes.any,
      disable: PropTypes.bool,
    })
  ).isRequired,

  dir: PropTypes.oneOf(DIRECTION),
  id: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(SIZE)),
  color: PropTypes.oneOf(COLOR),
};

Checkbox.defaultProps = {
  dir: 'horizontal',
  color: 'primary',
};
