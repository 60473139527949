import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';

const DIRECTION = ['horizontal', 'vertical'];
const SIZE = {
  lg: 'lg',
  sm: 'sm',
};
const COLOR = ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'dark', 'light'];

export default function Radio(props) {
  const {
    id,
    dir,
    size,
    value,
    color,
    options,
    getValue,
    className,
    ...others
  } = props;
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  useEffect(() => {
    if (state && getValue) {
      getValue(state);
    }
  }, [state]);

  const onChangeHandler = ({event, index, value}) => {
    setState(value);
  }

  return (
    <FormCheck className={clsx("dRadioWrapper", color , size)}>
      {options.map((option, index) => (
        <Form.Check
          type="radio"
          key={index}
          label={option.text}
          id={`${id}-${index}`}
          checked={state === option.value}
          disabled={option.disable}
          inline={dir === 'horizontal'}
          className={clsx(className, dir === 'horizontal' && 'horizontal')}
          onChange={(event) => onChangeHandler({event, index, value: option.value})}
        />
      ))}
    </FormCheck>
  );
}

Radio.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any,
      value: PropTypes.any,
      disable: PropTypes.bool,
    })
  ).isRequired,

  dir: PropTypes.oneOf(DIRECTION),
  id: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(SIZE)),
  color: PropTypes.oneOf(COLOR),
};

Radio.defaultProps = {
  dir: 'horizontal',
  color: 'primary',
};
