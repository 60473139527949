import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, Trans } from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import * as action from '../../store/actions/action';
import query from '../../utils/query';
import { logo } from '../../config';
import { getGravatar } from '../../utils/utils';

import 'react-toastify/dist/ReactToastify.css';
import {removeStorage} from "../../utils/storage";

class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutStatus: false,
    }
    this.logout = this.logout.bind(this);
  }

  openAside = () => {
    this.props.onOpenAside();
  };

  defaultPhoto = (e) => {
    e.target.src = '/images/avatar2.png';
  };

  logout(e) {
    e.preventDefault();
    query('POST', '/v1/auth/logout').then(() => {
      this.props.onLogout();
      this.setState({ logoutStatus: true });
      removeStorage('login');
    });
  }

  render() {
    const { account } = this.props;
    if (account && account.isLoggedIn === false) {
      return <br />;
    }

    return (
      <div className="align-items-center d-flex justify-content-between mobile-header">
        <div className="align-items-center d-flex">
          <button className="menu-button" onClick={this.openAside} type="button">
            <i className="icon-menu" />
          </button>
        </div>
        <figure className="logo">
          <img src={logo} alt="logo" />
        </figure>
        <div className="align-items-center d-flex">
          <div className="icon-alarm">
            <i className="icon-bell-o" />
          </div>
          <div className="profile dropdown">
            <div
              className="dropdown-button dropdown-toggle"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                onError={this.defaultPhoto.bind(this)}
                className="image-user"
                src={
                  account.user.profilePhoto || getGravatar(account.user.email)
                }
                alt="Profile avatar"
              />
            </div>
            <div className="dropdown-list" aria-labelledby="dropdownMenuLink">
              {
                account.permissions &&
                <Link to="/changePassword" className="dropdown-item">
                  <Trans i18nKey="header.CHANGE_PASSWORD">Change Password</Trans>
                </Link>
              }
              {
                account.permissions.billing &&
                <Link to="/billing" className="dropdown-item">
                  <Trans i18nKey='header.BILLING'>Billing</Trans>
                </Link>
              }
              {
                (account.permissions.account || account.permissions.billing) &&
                <hr />
              }
              <div className="dropdown-item cursor-pointer" onClick={this.logout} role="button" onKeyPress={this.logout} tabIndex={0}>
                <Trans i18nKey="header.LOGOUT">Logout</Trans>
              </div>
            </div>
          </div>
        </div>
        {this.state.logoutStatus && <Redirect to='/'/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  theme: state.themeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
  onOpenAside: () => dispatch({ type: action.OPEN_ASIDE }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translations')(MobileHeader));
