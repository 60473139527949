import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { validate } from './validators';
import Form from 'react-bootstrap/Form';
import NumberFormat from 'react-number-format';

const SIZE = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
};

const VARIANT = {
  outline: 'outline',
  standard: 'standard',
};

export default function Input(props) {
  const {
    size,
    value,
    label,
    onBlur,
    onFocus,
    variant,
    getValue,
    className,
    fullWidth,
    errorText,
    validators,
    labelClassName,
    type: typeState,
    hasError: hError,
    wrapperClassName,
    ...others
  } = props;

  const [event, setEvent] = useState();
  const [state, setState] = useState(value);
  const [hasError, setHasError] = useState(hError);
  const [active, setActive] = useState(false);
  const [touched, setTouched] = useState(false);
  const [validatorIndex, setValidatorIndex] = useState({});

  useEffect(() => {
    setHasError(hError);
    }, [hError]);

  useEffect(() => {
    setState(value);
    if (value) {
      setActive(true);
    }
  }, [value]);

  const onChangeHandler = (e) => {
    const val = e.target.value;
    setState(val);
    setTouched(true);
    setEvent(e);
    if (getValue) {
      getValue(val, e, hasError);
    }
  }

  const onNumberValue = (values)=>{
    const e = {
      target: {...props}
    }
    setEvent(e);
    if (getValue) {
      getValue(values.value, e, hasError);
    }
  };

  const onfocusHandler = (event) => {
    setActive(true);
    setTouched(true);
    if (onFocus) {
      onFocus(event);
    }
  }

  const onBlurHandler = (event) => {
    if (!state) {
      setActive(false);
    }
    if (onBlur) {
      onBlur(event);
    }

    if (validators && validators.length){
      const {isValid, validatorIndex} = validate(state, validators);
      setValidatorIndex(validatorIndex);
      setHasError(!isValid);
      if (getValue) {
        getValue(value, event, !isValid);
      }
    }
  }

  const getErrorText =() =>{
    if (!Array.isArray(errorText)) {
      return errorText;
    } else {
      const text = [];
      if (validatorIndex) {
        for (let i in validatorIndex) {
          if (!validatorIndex[i]) {
            text.push(<React.Fragment key={i}>{errorText[i]}</React.Fragment>);
            break;
          }
        }
      }
      return text;
    }
  };

  const labelClick = (e)=>{
    const element = e.target.previousElementSibling;
    element && element.focus();
  }

  const as = typeState === 'textarea' ?  {as:'textarea'} : {};
  const type = typeState !== 'textarea'? {type: typeState} : {};
  const thousandSeparator = typeState === 'thousandSeparator';

  return (
    <div className={clsx(
      'dInputWrapper',
      size,
      variant,
      wrapperClassName,
      fullWidth && 'fullWidth',
    )}
    >
      {!thousandSeparator ? (
        <Form.Control
          value={state}
          className={clsx(className)}
          onBlur={onBlurHandler}
          onFocus={onfocusHandler}
          onChange={onChangeHandler}
          {...as}
          {...type}
          {...others}
        />
      ) : (
        <NumberFormat
          thousandSeparator={true}
          className={clsx(className)}
          onValueChange={onNumberValue}
          defaultValue={state}
          {...others}
        />
      )}
      <label
        className={clsx(
          active && 'active',
          labelClassName
        )}
        onClick={labelClick}
      >
        {label}
      </label>
      {hasError && (
        <Form.Control.Feedback type="invalid">
          {getErrorText()}
        </Form.Control.Feedback>
      )}
    </div>
  );
}

Input.propTypes = {
  size: PropTypes.oneOf(Object.keys(SIZE)),
  variant: PropTypes.oneOf(Object.keys(VARIANT)),
};

Input.defaultProps = {
  size: SIZE.md,
  variant: 'outline',
  hasError: false,
};
