import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import BootstrapModal from 'react-bootstrap/Modal';
import Button from "../Button/Button";

const SIZE = ['sm', 'lg', 'xl'];

export default function Modal(props) {
  const {
    open,
    size,
    title,
    action,
    onClose,
    onApply,
    children,
    applyText,
    closeText,
    getStatus,
    className,
    closeBackdropOnClick,
  } = props;

  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
    setOpen(false);

    if (getStatus) {
      getStatus(false);
    }
  }

  function handleSave() {
    if (onApply) {
      onApply();
    }
    setOpen(false);

    if (getStatus) {
      getStatus(false);
    }
  }

  return (
    <BootstrapModal
      size={size}
      show={isOpen}
      onHide={handleClose}
      dialogClassName={className}
      backdrop={!closeBackdropOnClick && 'static'}
    >
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {children && children}
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        {action || (
          <React.Fragment>
            <Button variant="outline" color="danger" onClick={handleClose}>
              {closeText || 'Close'}
            </Button>
            <Button variant="outline" color="primary" onClick={handleSave}>
              {applyText || 'Save Changes'}
            </Button>
          </React.Fragment>
        )}
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
}
Modal.propTypes = {
  closeBackdropOnClick: PropTypes.bool,
  size: PropTypes.oneOf(SIZE),
};
Modal.defaultProps = {
  closeBackdropOnClick: false,
};
