import React from 'react';
import PropTypes from 'prop-types';
import { Card as BootstrapCard} from 'react-bootstrap';

const COLOR = ['primary','secondary','success','warning','danger','info','dark','light'];

export default function Card(props) {
  const { title, body, image, className, children, color } =  props;

  return (
    <BootstrapCard className={className} bg={color && color.toLowerCase()}>
      { image && <BootstrapCard.Img variant="top" src={image} /> }
      <BootstrapCard.Body>
        {title && <BootstrapCard.Title>{title}</BootstrapCard.Title>}
        {body || children}
      </BootstrapCard.Body>
    </BootstrapCard>
  );
}
Card.propTypes = {
  color: PropTypes.oneOf(COLOR),
};
Card.defaultProps = {};
