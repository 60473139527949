import React from 'react';
import Header from './theme/Header';

export default function Content ({ children }){
  return (
    <main className="main-content p-0">
      <Header />
      <div className="contentWrapper mx-3">
        {children}
      </div>
    </main>
  );
}
