import React from 'react';
import logger from '../../utils/logger';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    logger.error(error.message, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <p style={{ padding: '20px' }}>
          Something went wrong.
          <br />
          <u>
            <span onClick={() => (window.location = '/')} style={{ cursor: 'pointer' }}>
              Back to Home
            </span>
          </u>
        </p>
      );
    }
    return this.props.children;
  }
}
