import logger from './logger';

export function getFromStorage(key) {
  if (!key) return null;
  try {
    const valueStr = localStorage.getItem(key);
    if (valueStr) {
      return JSON.parse(valueStr);
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function setInStorage(key, obj) {
  if (!key) {
    logger.error('Error: key is missing');
  }
  try {
    localStorage.setItem(key, JSON.stringify(obj));
  } catch (e) {
    logger.error(e);
  }
}

export function removeStorage(key) {
  if (!key) {
    logger.error('Error: key is missing');
  }
  try {
    localStorage.removeItem(key);
  } catch (e) {
    logger.error(e);
  }
}
