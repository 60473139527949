import React, {useEffect, useState} from 'react';
import BootstrapForm from 'react-bootstrap/Form'

export default function Form(props) {
  const { children, onSubmit, valid, ...other } = props;
  const [validated, setValidated] = useState(false);
  const [isValid, setIsValid] = useState(valid);

  useEffect(() => {
    setIsValid(valid);
    }, [valid]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || !isValid) {
      event.preventDefault();
      event.stopPropagation();
      setIsValid(false);
    }
    onSubmit(event);
    setValidated(true);
  };

  return (
    <BootstrapForm
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      {...other}
    >
      {children}
    </BootstrapForm>
  );
}
Form.propTypes = {};
Form.defaultProps = {
  valid: false,
};
