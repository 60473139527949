import React, { Component } from "react";
import { connect } from 'react-redux';
import { copyright } from'../../config'
import {getLanguage} from "../../utils/localeTools";

class Copyright extends Component {
  constructor(props) {
    super(props);
  }

 render() {
   const { account } = this.props;
   const language = account.user.defaultLanguage || getLanguage();
   const copyrightText = copyright[language] || copyright.default;

   return <span className={this.props.className}>{copyrightText}</span>;
 }
}


const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps)(Copyright);
