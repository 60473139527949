import React from 'react';
import {Spinner} from '../element/danacoElements'
import clsx from 'clsx';

// See more loading icons here:
// https://fontawesome.com/how-to-use/on-the-web/styling/animating-icons
const PageLoader = () => (
  <>
    <main className="main-content p-0">
      <div className="main-wrapper">
        <div className="page-loader">
          <Spinner/>
        </div>
      </div>
    </main>
  </>
);

export default PageLoader;

export const Loader = ({ className }) =>(
  <div className={clsx("margin-50", className)}>
    <Spinner />
  </div>
);

export const Waiting = ({ className, message }) =>(
  <div className={clsx("margin-50", className)}>
    <Spinner />
    <p>{message}</p>
  </div>
);
