export const OPEN_ASIDE = 'OPEN_ASIDE';
export const CLOSE_ASIDE = 'CLOSE_ASIDE';
export const TOGGLE_ASIDE = 'TOGGLE_ASIDE';

export const LOGIN_USER = 'LOGIN_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const STORE_TOKEN = 'STORE_TOKEN';
export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_DEAFULT_ROUTE = 'SET_DEFAULT_ROUTE';
