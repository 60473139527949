import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import query from '../../utils/query';
import Content from '../../components/content';
import {getFromStorage} from '../../utils/storage';
import * as action from '../../store/actions/action';
import Loading from '../../components/theme/Loading';
import Sidebar from '../../components/theme/Sidebar';
import PageLoader from '../../components/common/PageLoader';
import RouteModule from '../../components/common/RouteModule';
import { registrationUrL, privacy, terms } from '../../config';
import { getDirection, getLanguage, setLanguage } from '../../utils/localeTools';
import {BrowserRouter as Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import { addBodyClass, setDirectionClass, setLanguageClass } from '../../utils/utils';

import WithoutActiveOrganization from '../public/content/WithoutActiveOrganization';

import 'react-toastify/dist/ReactToastify.min.css';
import '../../components/3rdParties/Vendor';
import '../../styles/style.scss';
import { Signup, Login, PasswordRecovery, PasswordSetup } from './activeRoute';
import { Report, Privacy, Terms, Subscription, Review, Help } from './activeRoute';
import { Dashboard, Profile, Account, Billing } from './activeRoute';
import { Reservation, DealManagement, PublicInformation, ChangePassword, Gallery } from './activeRoute';

const publicPages = [
  '',
  'signup',
  'login',
  'password-recovery',
  'first-login',
  'api-docs',
  'review',
  'report',
  'r', //Referral Link
  'privacy',
  'terms',
  'help',
  'subscription',
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hasValidSession: getFromStorage('login'),
    };
    this.failedAuth = this.failedAuth.bind(this);
  }

  componentDidMount() {
    addBodyClass('theme-light');

    if (this.state.hasValidSession) {
      this.setState({ isLoading: true });
      query('GET', '/v1/me', null, false)
        .then(async (json) => {
          const {onLogin} = this.props;
          if (json.success) {
            await onLogin({...json, isLoggedIn: true});
            this.setState({
              isLoading: false,
            });
          } else {
            this.failedAuth();
          }
        });
    } else {
      this.failedAuth();
    }
  }

  failedAuth() {
    const { account, onLogout } = this.props;
    onLogout();
    account.user = {};
    account.isLoggedIn = false;
    const host = `${window.location.protocol}//${window.location.host}`;
    const href = window.location.href.replace(host, '');
    const params = href.split('/');
    if (!publicPages.includes(params[1])) {
      window.location.href = '/login';
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { account, theme, onCloseAside } = this.props;
    const language = account.user.defaultLanguage || getLanguage();
    if (
      account.user.defaultLanguage !== 'undefined'
      && account.user.defaultLanguage !== getLanguage()
    ) {
      setLanguage(account.user.defaultLanguage);
    }
    setDirectionClass(getDirection(language));
    setLanguageClass(language);

    const mainClass = theme.openAside ? 'main collapsed-sidebar' : 'main';

    if (this.state.isLoading === true && this.state.hasValidSession ) {
      return (
        <div className={mainClass}>
          <Loading />
        </div>
      );
    }

    if (account.isLoggedIn === false) {
      return (
        <Router>
          <div>
            <Suspense fallback={<PageLoader/>}>
              <Switch>
                {/* Removes trailing slashes */}
                <Route
                  path="/:url*(/+)"
                  exact
                  strict
                  render={({ location }) => <Redirect to={location.pathname.replace(/\/+$/, '')} />}
                />
                {/* Removes duplicate slashes in the middle of the URL */}
                <Route
                  path="/:url(.*//+.*)"
                  exact
                  strict
                  render={({ match }) => (
                    <Redirect to={`/${match.params.url.replace(/\/\/+/, '/')}`} />
                  )}
                />
                <Route exact path="/login" component={Login} />
                <Route exact path={privacy} component={Privacy} />
                <Route exact path={terms} component={Terms} />
                <Route exact path="/signup" component={Signup} />
                <Route
                  exact
                  path="/r/:referralCode"
                  render={({ match }) => <Redirect to={`/${registrationUrL}/${match.params.referralCode}`} />}
                />

                <Route exact path="/password-recovery/:token?" component={PasswordRecovery} />
                <Route exact path="/first-login/:token?" component={PasswordSetup} />
                <Route
                  path="/subscription/:type?/:uuid?"
                  component={(props) => <Subscription {...props} />}
                />
                <Route
                  path="/review/:uuid?"
                  component={(props) => <Review {...props} />}
                />
                <Route
                  path="/report/:type?/:uuid?"
                  component={(props) => <Report {...props} />}
                />
                <Route
                path="/help/:id?"
                component={(props) => <Help {...props} />}
              />
                <Route path="*">
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </Suspense>
          </div>
        </Router>
      );
    }

    const { isSuperUser } = account.user;
    if (!isSuperUser && (!account.organization || !account.permissions)) {
      return (
          <WithoutActiveOrganization />
      );
    }

    // if (!parseInt(account.organization.activeSubscription, 10)) {
    //   return (
    //     <Router>
    //       <div className={mainClass}>
    //         <script>{account.organization.customJs}</script>
    //         <style>{account.organization.customCss}</style>
    //         <Sidebar />
    //         <Suspense fallback={<PageLoader />}>
    //           <Switch>
    //             <Route path="/billing" component={(props) => <Billing {...props} />} />
    //             <Route path="/*">
    //               <Redirect to="/billing" />
    //             </Route>
    //             <Route exact path="/privacy" component={Privacy} />
    //             <Route exact path="/terms" component={Terms} />
    //             <Route
    //             path="/help/:id?"
    //             component={(props) => <Help {...props} />}
    //           />
    //           </Switch>
    //         </Suspense>
    //
    //         <p className="main-footer">
    //           <Link className="mx-2" to={privacy}>
    //             privacy
    //           </Link>
    //           <Link to={terms}>terms</Link>
    //         </p>
    //       </div>
    //     </Router>
    //   );
    // }
    const permissions = account.permissions
      ? Object.keys(account.permissions)
      : [];
    let modules = account.organization && account.organization.modules
      ? account.organization.modules.split(',')
      : [];
    if (isSuperUser) {
      modules = ['dashboard'];
    }
    modules = modules.filter((module) => permissions.includes(module));
    const defaultRoute = account.defaultRoute || `/${modules[0]}`;

    return (
      <Router>
        <div className={mainClass}>

          <script>{account.organization.customJs}</script>
          <style>{account.organization.customCss}</style>
          <Sidebar />
          <div className="sidebar-cover" onClick={onCloseAside} />
          <Suspense fallback={<PageLoader />}>
            <Switch>

              <Route
                path="/:url*(/+)"
                exact
                strict
                render={({ location }) =>
                  <Content>
                    <Redirect to={location.pathname.replace(/\/+$/, '')} />
                  </Content>
                }
              />
              {/* Removes duplicate slashes in the middle of the URL */}
              <Route
                path="/:url(.*//+.*)"
                exact
                strict
                render={({ match }) => (
                  <Content>
                    <Redirect to={`/${match.params.url.replace(/\/\/+/, '/')}`} />
                  </Content>
                )}
              />

              <RouteModule
                module="dashboard"
                path="/dashboard"
                component={(props) =>
                  <Content>
                    <Dashboard {...props} />
                  </Content>
                }
              />

              <RouteModule
                module="profile"
                path="/profile"
                component={(props) =>
                  <Content>
                    <Profile {...props} />
                  </Content>
                }
              />

              <Route
                module="account"
                path="/account"
                component={(props) =>
                  <Content>
                    <Account {...props} />
                  </Content>
                }
              />

              <Route
                path="/reserve"
                component={
                  (props) => <Content><Reservation {...props} /></Content>
                }
              />

              <Route
                path="/dealManagement"
                component={
                  (props) => <Content><DealManagement {...props} /></Content>
                }
              />
              <Route
                path="/publicInformation"
                component={
                  (props) => <Content><PublicInformation {...props} /></Content>
                }
              />

              <Route
                path="/changePassword"
                component={
                  (props) => <Content><ChangePassword {...props} /></Content>
                }
              />

              {/*<Route*/}
              {/*  path="/gallery"*/}
              {/*  component={*/}
              {/*    (props) => <Content><Gallery {...props} /></Content>*/}
              {/*  }*/}
              {/*/>*/}

              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route path="/*">
                <Redirect to="/reserve" />
              </Route>
              <Route
                path="/help/:id?"
                component={(props) =>
                  <Content>
                    <Help {...props} />
                  </Content>
                }
              />

            </Switch>
          </Suspense>

          {/*<Footer />*/}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  theme: state.themeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  onLogin: (payload) => dispatch({ ...payload, type: action.LOGIN_USER }),
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
  onCloseAside: () => dispatch({ type: action.CLOSE_ASIDE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
