const sideMenu = [
  {
    name: 'Reservation',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.RESERVATION',
    path: '/reserve',
    // module: 'dashboard',
  },
  {
    name: 'Deal Management',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.DEAL_MANAGEMENT',
    path: '/dealManagement',
    // module: 'dashboard',
  },
  {
    name: 'Public information',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.PUBLIC_INFORMATION',
    path: '/publicInformation',
    // module: 'dashboard',
  },
  // {
  //   name: 'Gallery',
  //   icon: 'icon-Shape',
  //   translate: 'sidebar.nav.GALLERY',
  //   path: '/gallery',
  //   module: 'dashboard',
  // },
];

export default sideMenu;
